import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {
    i_conditional,
    i_entryContextDto,
    i_fileSourceDto,
    i_formattingTable,
    i_formattingText,
    i_geojsonTemplateDto,
    i_mapMarker,
    i_mapPolyline,
    i_mapTemplate,
    i_request,
    i_sourceDto,
    i_switch, i_table, i_text, t_formatting,
    t_operation
} from "@/types/interfaces/structure.interfaces";
import {Feature, FeatureCollection, lineString, point, polygon} from "@turf/turf";
import {
    i_context,
    i_fetchedMapObjects,
    i_mapObject,
    i_marker,
    i_polyline
} from "@/types/interfaces/frontend.interfaces";
import {DataUtils} from "@/utils/data.utils";
import {DATA_WILDCARDS, MDI} from "@/types/enums/frontend.enums";
import {
    isConditional,
    isGeojsonLineString,
    isGeojsonPoint,
    isGeojsonPolygon,
    isMapMarker,
    isMapPolyline,
    isOperation,
    isSwitch,
    isText
} from "@/types/typeguards/general.guards";
import {CONTEXT_TYPES, MAP_TEMPLATE} from "@/types/enums/general.enums";
import {StringUtils} from "@/utils/string.utils";
import {isCachedResponse, isFormatTable, isFormatText} from "@/types/typeguards/frontend.guards";
import {UNKNOWN_TEXT} from "@/constants";
import {Log} from "@/utils/log";
import {OperationUtils} from "@/utils/operation.utils";
import {i_axiosOptions, i_truncateOptions} from "@/types/interfaces/workers.interfaces";


const log = new Log("FetchWorkerUtils");

export class FetchWorkerUtils {

    private static accessToken: string | undefined = undefined;

    private static storage: Record<string, any> = {};

    public static setCsrfToken(csrfToken: string) {
        log.debug("Set csrf token.")
        axios.defaults.headers.common['x-csrf-token'] = csrfToken;
    }

    public static setAccessToken(accessToken: string) {
        log.debug("Set access token.");
        FetchWorkerUtils.accessToken = accessToken;
    }

    public static clearStorageFor(id: string) {
        if (FetchWorkerUtils.storage[id]) {
            delete FetchWorkerUtils.storage[id];
        }
    }

    public static fetchRequest(request: i_request) {
        log.debug(`fetch request to '${request.url}', authentication ${request.permission ? '' : 'not '}required.`);
        if (!(request.cachingRate && request.cachingRate < 0) && !DataUtils.containsWildcardOrConditional(request.url)) {
            const cachedObj = FetchWorkerUtils.storage[request.url];
            if (cachedObj !== undefined) {
                if (isCachedResponse(cachedObj) && cachedObj.timestamp + (request.cachingRate ? request.cachingRate : 60) * 1000 > Date.now()) {
                    return Promise.resolve(request.dataRoot !== undefined && request.dataRoot !== "" ?
                        DataUtils.getValueByPath(request.dataRoot, cachedObj.data) :
                        cachedObj.data as any);
                } else {
                    delete FetchWorkerUtils.storage[request.url];
                }
            }
        }
        return axios.get(request.url, {
            timeout: 10000,
            //headers: { Authorization: request.permission ? `Bearer ${FetchWorkerUtils.accessToken}` : undefined }
            headers: { Authorization: `Bearer ${FetchWorkerUtils.accessToken}` }
        })
            .then(response => {
                // get data by data root definition
                const cachedObj = {
                    timestamp: Date.now(),
                    data: response.data as any
                }
                FetchWorkerUtils.storage[request.url] = cachedObj;
                return request.dataRoot !== undefined && request.dataRoot !== "" ?
                    DataUtils.getValueByPath(request.dataRoot, cachedObj.data) :
                    cachedObj.data as any;
            });
    }

    public static axiosRequest(
        config: AxiosRequestConfig, options?: i_axiosOptions
    ): Promise<{ status: number; data: any } | { status: number; message: string }> {
        const auth = options?.auth;
        const noReconnect = options?.noReconnect === true;
        const doTruncation = options?.truncateData === true;
        if (auth) {
            config.headers = {...config.headers, Authorization: `Bearer ${FetchWorkerUtils.accessToken}`}
        }
        return axios.request(config)
            .then((response: AxiosResponse) => {
                return { status: response.status, data: doTruncation ? FetchWorkerUtils.truncate(response.data, options?.truncateOptions) : response.data };
            })
            .catch((error: AxiosError) => {
                if (!noReconnect && JSON.parse(JSON.stringify(error))["status"] === 403) {
                    return axios.get("/api/csrf-token")
                        .then((csrfResponse: AxiosResponse) => {
                            axios.defaults.headers.common['x-csrf-token'] = csrfResponse.data.token;
                            options = options ? options : {};
                            options.noReconnect = true;
                            return FetchWorkerUtils.axiosRequest(config, options);
                        })
                        .catch((csrfError: AxiosError) => {
                            return { status: JSON.parse(JSON.stringify(error))["status"], message: "Unable to retrieve CSRF token!" };
                        });
                }
                return { status: JSON.parse(JSON.stringify(error))["status"], message: error.message };
            })
    }

    private static truncate(
        obj: any,
        options?: i_truncateOptions
    ) {
        const _options = {
            onlyObjectArrays: options?.onlyObjectArrays || false,
            maxElements: options?.maxElements || 3
        }
        if (Array.isArray(obj)) {
            if (typeof obj[0] === "object" || !_options.onlyObjectArrays) {
                return obj.slice(0, _options.maxElements);
            }
            return obj;
        } else if (typeof obj === "object" && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                newObj[key] = FetchWorkerUtils.truncate(obj[key], _options);
            }
            return newObj;
        }
        return obj;
    }

    private static processRequestOrOperation(requestOrOperation: i_request | t_operation) {
        return isOperation(requestOrOperation) ? OperationUtils.applyOperation(requestOrOperation) : FetchWorkerUtils.fetchRequest(requestOrOperation);
    }

    public static getGeojsonForFileSources(fileSources: i_fileSourceDto[]): Promise<FeatureCollection> {
        log.debug("getGeojsonForFileSources")
        const sourcePromises = fileSources.map(source => {
            return FetchWorkerUtils.processRequestOrOperation(source.request)
                .then(data => {
                    return this.buildFeatureCollection(data, source);
                });
        });
        return Promise.all(sourcePromises)
            .then(featureCollections => {
                const aggregateFeatureCollection: FeatureCollection = {
                    type: "FeatureCollection",
                    features: featureCollections
                        .map(featureCollection => featureCollection.features)
                        .reduce((aggregate, features) => {
                            return [ ...aggregate, ...features ]
                        })
                };
                return aggregateFeatureCollection;
            });
    }

    public static getMapObjectsForSource(source:i_sourceDto): Promise<i_fetchedMapObjects> {
        let startTime = new Date().getTime();
        return FetchWorkerUtils.processRequestOrOperation(source.request)
            .then(data => {
                log.debug(`fetching the data took ${new Date().getTime() - startTime}ms`);
                startTime = new Date().getTime();
                const mapObjects = this.buildMapObjects(data, source);
                log.debug(`processing the data took ${new Date().getTime() - startTime}ms`);
                return mapObjects;
            });
    }

    public static getContextForMapObject(template: i_mapObject): Promise<i_context> {
        log.debug("get context for map object");
        const context: i_entryContextDto = template.context;
        const source = context.request;
        if (source !== undefined) {
            const injectedSource = DataUtils.replaceByMatrix(source, DataUtils.REP_MATRIX, template.data);
            return FetchWorkerUtils.processRequestOrOperation(injectedSource)
                .then(data => {
                    // already done in fetchRequest
                    //const resData = DataUtils.getValueByPath(source.dataRoot !== undefined ? DataUtils.replaceByMatrix(source.dataRoot, DataUtils.REP_MATRIX, template.data) : "", data);
                    //return this.parseFormatting(context, resData);
                    return this.parseFormatting(context.formatting, data);
                });
        } else {
            return new Promise<i_context>(() => this.parseFormatting(context.formatting))
        }
    }

    public static replaceByMatrix(obj:any, data:any): any {
        return DataUtils.replaceByMatrix(obj, DataUtils.REP_MATRIX, data);
    }

    private static findPaths(obj: any, searchTerms: DATA_WILDCARDS[]): { path: string; targets: DATA_WILDCARDS[] }[] {

        const paths: { path: string; targets: DATA_WILDCARDS[] }[] = [];

        const searchInObject = (currentObj: any, currentPath: string)=> {
            for (const key in currentObj) {
                if (key in currentObj) {
                    const newPath = currentPath ? `${currentPath}.${key}` : key;
                    const value = currentObj[key];

                    if (typeof value === 'string') {
                        const matchingTerms = searchTerms.filter(term => value.includes(`{{${term}`));
                        if (matchingTerms.length > 0) {
                            paths.push({ path: newPath, targets: matchingTerms });
                        }
                    } else if (Array.isArray(value)) {
                        for (let i = 0; i < value.length; i++) {
                            const arrayPath = `${newPath}[${i}]`;
                            const arrayValue = value[i];

                            if (typeof arrayValue === 'string') {
                                const matchingTerms = searchTerms.filter(term => arrayValue.includes(`{{${term}`));
                                if (matchingTerms.length > 0) {
                                    paths.push({ path: arrayPath, targets: matchingTerms });
                                }
                            } else if (typeof arrayValue === 'object' && arrayValue !== null) {
                                searchInObject(arrayValue, arrayPath);
                            }
                        }
                    } else if (typeof value === 'object' && value !== null) {
                        searchInObject(value, newPath);
                    }
                }
            }
        }

        searchInObject(obj, '');

        return paths;
    }

    private static buildMapObjects(data:any[], source: i_sourceDto): i_fetchedMapObjects {
        // find data array in response body
        /*const resData = source.request.dataRoot !== undefined && source.request.dataRoot !== "" ?
            DataUtils.getValueByPath(source.request.dataRoot, data) :
            data as any[];*/
        //const markers = [] as L.Marker[];
        const mapObjects: i_fetchedMapObjects = {
            markers: [] as i_marker[],
            polylines: [] as i_polyline[]
        }
        // get all paths that include wildcards and wildcard targets within the mapTemplate
        const paths = this.findPaths(source.mapTemplate, DataUtils.REP_MATRIX.map(rep => rep.target));
        log.info(JSON.stringify(paths));
        // find marker parameters in received data
        data.forEach((d: any, idx: number) => {
            const template: i_mapTemplate = DataUtils.unproxy(source.mapTemplate);
            for (const p of paths) {
                DataUtils.setValueByPath(
                    template,
                    p.path,
                    DataUtils.replaceByMatrix(DataUtils.getValueByPath(p.path, source.mapTemplate),
                        // use only members of the replacement matrix that were found
                        DataUtils.REP_MATRIX.filter(rep => p.targets.some(filter => rep.target == filter)), d)
                );
            }
            // prepare template for each element
            if (isMapMarker(template)) {
                const marker = this.buildMarker(`${source.id}-${idx}`, template, d, source.context);
                if (marker !== undefined) {
                    mapObjects.markers.push(marker);
                }
            } else if (isMapPolyline(template)) {
                const polyline = this.buildPolyline(`${source.id}-${idx}`, template, d, source.context);
                if (polyline !== undefined) {
                    mapObjects.polylines.push(polyline);
                }
            }
        });
        return mapObjects;
    }

    private static buildFeatureCollection(data: any[], source: i_fileSourceDto): FeatureCollection {
        log.debug("buildFeatureCollection");
        // find data array in response body
        /*const resData = (source.request.dataRoot !== undefined && source.request.dataRoot !== "") ?
            DataUtils.getValueByPath(source.request.dataRoot, data) :
            data as any[];*/
        //const markers = [] as L.Marker[];
        const collection: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }
        // get all paths that include wildcards and wildcard targets within the mapTemplate
        const paths = this.findPaths(source.geojsonTemplate, DataUtils.REP_MATRIX.map(rep => rep.target));
        // find parameters in received data
        for (const d of data) {
            const template: i_geojsonTemplateDto = DataUtils.unproxy(source.geojsonTemplate);
            for (const p of paths) {
                DataUtils.setValueByPath(
                    template,
                    p.path,
                    DataUtils.replaceByMatrix(DataUtils.getValueByPath(p.path, source.geojsonTemplate),
                        // use only members of the replacement matrix that were found
                        DataUtils.REP_MATRIX.filter(rep => p.targets.some(filter => rep.target == filter)), d)
                );
            }
            // prepare template for each element
            if (isGeojsonPoint(template.geometry)) {
                const coordinates = template.geometry.coordinates;
                if (Array.isArray(coordinates) && coordinates.every(d => !Number.isNaN(d) && !Number.isNaN(d))) {
                    // in contrast to leaflet representation we only want to swap coordinates if they should be swapped for
                    // the map, since geojson takes coordinates in reverse order in comparison to leaflet
                    const feature: Feature = point(
                        !(source.swapCoordinates == true) ?
                            [coordinates[1], coordinates[0]] as [number, number] :
                            coordinates as [number, number],
                        template.properties
                    );
                    collection.features.push(feature);
                }
            } else if (isGeojsonLineString(template.geometry)) {
                const coordinates = template.geometry.coordinates;
                if (Array.isArray(coordinates) && coordinates.every(c => c.length == 2 &&
                    typeof c[0] === 'number' && typeof c[1] === 'number')) {
                    const feature: Feature = lineString(
                        !(source.swapCoordinates == true) ?
                            coordinates.map(c => [c[1], c[0]]) as [number, number][] :
                            coordinates as [number, number][],
                        template.properties
                    );
                    collection.features.push(feature);
                }
            } else if (isGeojsonPolygon(template.geometry)) {
                const coordinates = template.geometry.coordinates;
                if (Array.isArray(coordinates) && coordinates.every(c => c.length == 2 &&
                    typeof c[0] === 'number' && typeof c[1] === 'number')) {
                    const feature: Feature = polygon(
                        !(source.swapCoordinates == true) ?
                            coordinates.map(sa => sa.map(c => [c[1], c[0]])) as [number, number][][] :
                            coordinates as [number, number][][],
                        template.properties
                    );
                    collection.features.push(feature);
                }
            }
        }
        return collection;
    }

    private static buildMarker(id: string, template: i_mapMarker, data: any, context: i_entryContextDto): i_marker | undefined {
        if (Array.isArray(template.coordinates) && template.coordinates.every(d => !Number.isNaN(d) && !Number.isNaN(d))) {
            return {
                id: id,
                type: MAP_TEMPLATE.MARKER,
                name: template.name,
                coordinates: template.swapCoordinates ?
                    [template.coordinates[1], template.coordinates[0]] as [number, number] :
                    template.coordinates as [number, number],
                color: template.color !== undefined ? this.checkForConditionalOrSwitch(template.color) : "#50accb",
                icon: template.icon !== undefined ? this.checkForConditionalOrSwitch(template.icon) : MDI.INFO,
                iconColor: template.iconColor !== undefined ? this.checkForConditionalOrSwitch(template.iconColor) : null,
                data: data,
                context: context
            } as i_marker;
        } else {
            log.error(`Required parameters for marker are missing or invalid: type(${template.type}), name(${template.name}), coordinates(${JSON.stringify(template.coordinates)})`);
            return undefined;
        }
    }

    private static buildPolyline(id: string, template: i_mapPolyline, data: any, context: i_entryContextDto): i_polyline | undefined {
        if (Array.isArray(template.coordinates) && template.coordinates.every(c => c.length == 2 &&
            typeof c[0] === 'number' && typeof c[1] === 'number')) {
            return {
                id: id,
                type: MAP_TEMPLATE.POLYLINE,
                name: template.name,
                coordinates: template.swapCoordinates ?
                    template.coordinates.map(c => [c[1], c[0]]) as [number, number][] :
                    template.coordinates as [number, number][],
                color: template.color !== undefined ? this.checkForConditionalOrSwitch(template.color) : "#50accb",
                icon: template.icon !== undefined ? this.checkForConditionalOrSwitch(template.icon) : MDI.INFO,
                iconColor: template.iconColor !== undefined ? this.checkForConditionalOrSwitch(template.iconColor) : null,
                data: data,
                context: context,
                markCenter: template.markCenter !== undefined && template.markCenter
            } as i_polyline;
        } else {
            log.error(`Required parameters for polyline are missing or invalid: type(${template.type}), name(${template.name}), coordinates(${JSON.stringify(template.coordinates)})`);
            return undefined;
        }
    }

    private static checkForConditionalOrSwitch(value: any): string | number | boolean {
        if (isConditional(value)) {
            return this.resolveConditional(value);
        } else if (isSwitch(value)) {
            return this.resolveSwitch(value)
        } else {
            return value;
        }
    }

    private static resolveConditional(conditional: i_conditional): string | number | boolean {
        if (StringUtils.evaluateExp(conditional.exp) == true) {
            if (isConditional(conditional.true)) {
                return this.resolveConditional(conditional.true);
            } else if (isSwitch(conditional.true)) {
                return this.resolveSwitch(conditional.true);
            } else {
                return conditional.true;
            }
        } else {
            if (isConditional(conditional.false)) {
                return this.resolveConditional(conditional.false);
            } else if (isSwitch(conditional.false)) {
                return this.resolveSwitch(conditional.false);
            } else {
                return conditional.false;
            }
        }
    }

    private static resolveSwitch(_switch: i_switch): string | number | boolean {
        for (const _case of _switch.cases) {
            if (StringUtils.evaluateExp(_case.exp) == true) {
                if (isConditional(_case.value)) {
                    return this.resolveConditional(_case.value);
                } else if (isSwitch(_case.value)) {
                    return this.resolveSwitch(_case.value);
                } else {
                    return _case.value
                }
            }
        }
        if (isConditional(_switch.default)) {
            return this.resolveConditional(_switch.default);
        } else if (isSwitch(_switch.default)) {
            return this.resolveSwitch(_switch.default);
        }
        return _switch.default;
    }

    private static parseFormatting(formatting: t_formatting, resData?: any) {
        try {
            if (isFormatText(formatting)) {
                log.debug("Format to text");
                // pass the text sources to the callback
                return {
                    type: CONTEXT_TYPES.TEXT,
                    value: this.formatToText(formatting as i_formattingText, resData)
                }
            } else if (isFormatTable(formatting)) {
                log.debug("Format to table");
                // pass the table sources to the callback
                return {
                    type: CONTEXT_TYPES.TABLE,
                    value: this.formatToTable(formatting, resData)
                }
            }
        } catch (err) {
            log.error("Received unparsable data: " + err);
        }
        return {
            type: CONTEXT_TYPES.TEXT,
            value: {
                en: "ERROR: Data cannot be parsed!",
                de: "FEHLER: Daten konnten nicht verarbeitet werden!"
            }
        }
    }

    private static formatToText(formatting:i_formattingText, resData: any): i_text {
        log.debug(JSON.stringify(formatting.value));
        return DataUtils.replaceByMatrix(formatting.value, DataUtils.REP_MATRIX, resData)
    }

    private static formatToTable(formatting:i_formattingTable, resData: any): i_table {
        const cols = formatting.value.columns.map(e => {
            return {
                name: isText(e.name) ? e.name : UNKNOWN_TEXT,
                sort: e.sort,
                description: isText(e.description) ? e.description : undefined
            }
        });
        const rows = Array.isArray(resData) ? (resData as any[]).map(data => {
            const rowValues: string[] = [];
            for (const s of formatting.value.rows) {
                if (typeof s !== "string") {
                    log.error("invalid row value: " + s)
                    return [];
                }
                const val = DataUtils.getValueByPath(s, data);
                if (val === null || val === undefined) {
                    rowValues.push("");
                } else {
                    rowValues.push(val.toString());
                }
            }
            return rowValues;
        }) : [];
        return {
            columns: cols,
            rows: rows
        }
    }
}
